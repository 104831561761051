<template>
  <van-row class="buytrain">
    <van-image class="pic_margin" width="100vw" src="/banner_train.jpg" />
    <van-row style="padding-top:1rem" class="pic-next-cont itembk">
      <!-- 始末车站选择cell -->
      <van-cell>
        <template slot="title">
          <van-notice-bar class="tips" v-if="tips" left-icon="info-o">{{isView?'':'申请单'}}所选城市没有火车站，请调整行程。</van-notice-bar>
          <div class="gap" v-if="tips"></div>
          <div class="gap" v-if="tips"></div>
          <van-row type="flex" justify="space-between" align="bottom" style="height: 20px;">
            <van-col>
              <van-row type="flex" justify="center">
                <span class="buy-text">出发城市</span>
              </van-row>
            </van-col>
            <van-col>
              <van-row type="flex" justify="center">
                <div class="buy-text"></div>
              </van-row>
            </van-col>
            <van-col>
              <van-row type="flex" justify="center">
                <span class="buy-text">到达城市</span>
              </van-row>
            </van-col>
          </van-row>
          <van-row type="flex" justify="space-between" align="center">
            <van-col span="8">
              <van-row type="flex" justify="start" @click="chooseTrainStation('train/setOrderFrom')">
                <h4 class="animate__animated animate__delay-08" ref="left">
                  <span :class="orderInfo.trainFromStation=='请选择'?'red':''">{{orderInfo.trainFromStation}}</span>
                </h4>
              </van-row>
            </van-col>
            <van-col span="8">
              <van-row @click="changeCity" type="flex" justify="center">
                <van-icon class="iconfont animate__animated" class-prefix="icon" name="wangfandijia" ref="change" />
              </van-row>
            </van-col>
            <van-col span="8">
              <van-row type="flex" justify="end" @click="chooseTrainStation('train/setOrderTo')">
                <h4 class="animate__animated animate__delay-08" ref="right">
                  <span :class="orderInfo.trainToStation=='请选择'?'red':''">{{orderInfo.trainToStation}}</span>
                </h4>
              </van-row>
            </van-col>
          </van-row>
        </template>
      </van-cell>
      <!-- 始末车站选择cell -->
      <!-- 出发时间选择cell -->
      <van-cell>
        <template slot="title">
          <van-row type="flex" justify="start" align="bottom" style="height: 20px;">
            <van-col>
              <van-row type="flex" justify="center">
                <span class="buy-text">出发日期</span>
              </van-row>
            </van-col>
          </van-row>
          <van-row type="flex" justify="start" align="center" style="height: 40px">
            <van-col>
              <van-row type="flex" justify="start" align="bottom" @click="show_calendar = true">
                <van-col>
                  <span class="h4">{{orderInfo.goBackDate[0]| comverTime('YYYY-MM-DD')}}</span>
                </van-col>
                <van-col>
                  <span>{{orderInfo.goBackDate[0]| WhichDay}}</span>
                </van-col>
              </van-row>
            </van-col>
          </van-row>
        </template>
      </van-cell>
      <!-- 出发时间选择cell -->
      <!-- 车型选择cell -->
      <van-cell>
        <template slot="title">
          <van-row type="flex" justify="start" align="bottom" style="height: 20px;">
            <van-col>
              <van-row type="flex" justify="center">
                <span class="buy-text">车次类型</span>
              </van-row>
            </van-col>
          </van-row>
          <van-row type="flex" justify="start" align="center" style="height: 40px">
            <van-col>
              <van-row type="flex" justify="start" align="bottom" @click="changeTrainType">
                <van-col>
                  <span class="h4">{{orderInfo.trainType}}</span>
                </van-col>
              </van-row>
            </van-col>
          </van-row>
        </template>
      </van-cell>
      <!-- 车型选择cell -->
      <!-- 搜索按钮cell -->
      <van-cell>
        <template slot="title">
          <van-row type="flex" justify="center" align="center" style="height: 60px">
            <van-button block @click="searchTrain" class="head-background">搜 索</van-button>
          </van-row>
        </template>
      </van-cell>
      <!-- 搜索按钮cell -->
    </van-row>

    <!--日期选择-->
    <van-calendar v-model="show_calendar" confirm-text="确定" :show-confirm="false"
      confirm-disabled-text="请选择出发时间" :default-date="new Date(orderInfo.goBackDate[0])" :max-date="maxDate"
      @confirm="calendarConfirm" />

    <!--火车类型选择-->
    <van-action-sheet v-model="show_train_type" :close-on-click-action="true" :actions="train_type_actions"
      @select="trainTypeOnSelect" />

    <LoadingCircle :show="loading" />
  </van-row>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import LoadingCircle from "@/components/LoadingCircle";
import { WhichDay, AddDate, DepartureDate } from "@/services/date.js";
import { checkCity } from "@/services/train";
import { CityRE } from "@/common/config.js";
export default {
  computed: {
    ...mapGetters([
      "personList",
      "travelInfo",
      "orderInfo",
      "config_controlLevel"
    ]),
    // 是否为预览页面
    isView: function() {
      return this.$route.path.toLocaleLowerCase().includes("view");
    },
    maxDate: function() {
      return new Date(AddDate(new Date(), 29));
    },
    // 是否为返程，终到站等于行程出发地
    isBack: function() {
      return this.orderInfo.trainToStation == this.travelInfo.travelFrom;
    },
    // 是否提示？（匹配不到行程单的车站时候需要提示）
    tips: function() {
      return (
        this.orderInfo.trainToStation == "请选择" ||
        this.orderInfo.trainFromStation == "请选择"
      );
    }
  },
  components: { LoadingCircle },
  mounted() {
    // 初始化订单信息
    let order = this.travelInfo
    if (
      this.orderInfo.goBackDate.length < 2 || !order.routeCityEnd ||
      (!order.routeCityEnd.includes(this.orderInfo.trainToStation.replace(CityRE, ''))&&!this.isView) ||
      (!order.routeCityEnd.includes(this.orderInfo.trainFromStation.replace(CityRE, ''))&&!this.isView)
    ) {
      let obj = {
        goBackDate: [order.startDate, order.endDate], // 往返日期
        trainFromStation: (order.routeCityEnd && order.routeCityEnd.constructor == Array) ? order.routeCityEnd[0] : "请选择", // 出发站
        trainToStation: (order.routeCityEnd && order.routeCityEnd.constructor == Array) ? order.routeCityEnd[1] : "请选择", // 终点站
        trainType: "不限车型" //车次类型
      };
      // 筛选异常车站。
      obj.trainToStation = checkCity(obj.trainToStation).name || "请选择";
      obj.trainFromStation = checkCity(obj.trainFromStation).name || "请选择";
      this.$store.dispatch("train/setOrderInfo", obj);
    }
  },
  data() {
    return {
      loading: false,
      canChange: true, // 是否可以点击交换城市
      show_calendar: false, // 展示日历
      show_train_type: false, // 车次类型选择开关
      train_type_actions: [
        { name: "不限车型" },
        { name: "高铁 / 动车" },
        { name: "普通" }
      ]
    };
  },
  methods: {
    // 交换城市动画，1秒禁止点击，过度效果参考animate.css 重写了部分动画效果在全局样式中查看
    changeCity() {
      if (this.config_controlLevel >= 20) return; // 大于20级管控不能点
      if (this.canChange) {
        this.canChange = false;
        // 解构置换数据
        [this.orderInfo.trainFromStation, this.orderInfo.trainToStation] = [
          this.orderInfo.trainToStation,
          this.orderInfo.trainFromStation
        ];
        this.orderInfo.goBackDate.reverse(); // 切换时间
        // 动画由css实现
        if (this.$refs.left)
          this.$refs.left.classList.add("animate__doubleSlideInRight");
        if (this.$refs.right)
          this.$refs.right.classList.add("animate__doubleSlideInLeft");
        if (this.$refs.change) this.$refs.change.classList.add("animate__flip");
        setTimeout(() => {
          this.canChange = true;
          if (this.$refs.left)
            this.$refs.left.classList.remove("animate__doubleSlideInRight");
          if (this.$refs.right)
            this.$refs.right.classList.remove("animate__doubleSlideInLeft");
          if (this.$refs.change)
            this.$refs.change.classList.remove("animate__flip");
        }, 1000);
        // 更新vuex
        this.$store.dispatch("train/setOrderInfo", this.orderInfo);
      }
    },

    // 选择城市
    chooseTrainStation(vuxFunction) {
      this.loading = true;
      setTimeout(() => {
        this.$emit('chooseTrainStation', vuxFunction)
      }, 200);
    },
    // 日历 选择日期
    calendarConfirm(date) {
      this.orderInfo.goBackDate[0] = moment(date).format("YYYY-MM-DD");
      this.show_calendar = false;
    },
    // 保存车次类型
    trainTypeOnSelect(item) {
      this.orderInfo.trainType = item.name;
      this.$store.dispatch("train/setOrderInfo", this.orderInfo);
    },
    // 打开修改车次类型
    changeTrainType() {
      this.show_train_type = true;
    },
    // 搜索按钮
    searchTrain() {
      if (
        !this.orderInfo.trainToStation ||
        this.orderInfo.trainToStation == "请选择" ||
        !this.orderInfo.trainFromStation ||
        this.orderInfo.trainFromStation == "请选择"
      ) {
        return this.$toast.fail({
          duration: 1000,
          message: "请选择城市"
        });
      }
      if (this.orderInfo.trainFromStation == this.orderInfo.trainToStation)
        return this.$toast.fail("始末站相同不妨尝试下用车");
      this.$store.dispatch("train/setOrderInfo", this.orderInfo);
      this.$emit("searchTrain", this.active);
    },

  },
  filters: {
    WhichDay: WhichDay // 过滤器。 今日，明日等
  }
};
</script>

<style lang="less" src="@/styles/buyCommon.less" scoped></style>